import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import "./LoanRequest.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CurrencyFormat from 'react-currency-format'

  const loanAmount = localStorage.getItem("loanAmount");
  const weeklyFee = localStorage.getItem("weeklyFee")

const LoanRequest = () => {
  const [loan, setLoan] = useState("");
  const [paybackDate, setPaybackDate] = useState(null)
  const [redirect, setRedirect] = useState("")



  const handleSubmit = (e) => {
    e.preventDefault()
    const paydate = paybackDate.toLocaleDateString("en-CA");
    localStorage.setItem("date", paydate)
    localStorage.setItem("amount", loan)
    setRedirect('/pin')
    setTimeout(() => {
      window.location.reload(1);
    }, 10)
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <>
      <div className="container my-4 d-flex justify-content-center align-items-center">
        <div className="row">
          <div className="col-md-12 card p-3" style={{ maxWidth: "400px" }}>
            <div className="card-body">
              <h3 className="my-3">Loan Request!</h3>
              <p>Log in to your CashPally account to complete your purchase</p>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Order amount</label>
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <span className="currency">NGN</span>
                    <CurrencyFormat
                      value={loanAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                      renderText={(value) => (
                        <>
                          <input
                            className="form-control user-input"
                            type="text"
                            defaultValue={value}
                            readOnly={true}
                            required
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Weekly fee</label>
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="currency">NGN</span>
                    <CurrencyFormat
                      value={weeklyFee}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                      renderText={(value) => (
                        <>
                          <input
                            className="form-control user-input"
                            type="text"
                            defaultValue={value}
                            readOnly={true}
                            required
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                <span className="text-danger mb-3">
                  All loans attract a weekly fee (or interest). The first fee
                  will be charged once your loan request is approved.
                </span>
                <div className="mt-3">
                  <label className="form-label">
                    When do you intend to pay back?
                  </label>
                  <div className="row">
                    <div>
                      <DatePicker
                        required
                        className="date form-control user-input"
                        placeholderText="Select Date"
                        minDate={new Date()}
                        selected={paybackDate}
                        onChange={(date) => setPaybackDate(date)}
                        dateFormat="dd/MM/yyyy"
                        isClearable
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-success w-100 mt-5">
                    Proceed
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanRequest;
