import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Success from './components/success/Success';
import Failure from './components/failure/Failure';
import OrderDetails from './components/orders/OrderDetails';
import Pin from './components/pin/Pin';
import LoanRequest from './components/loan-request/LoanRequest';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failure" element={<Failure />} />
        <Route path="/order-details" element={<OrderDetails />} />
        <Route path="/pin" element={<Pin />} />
        <Route path="/loan-request" element={<LoanRequest />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
