import React from 'react'

const Failure = () => {
  return (
    <div className="container text-center">
      <div className="row">
        <div className="col-md-12">
          <p className="head">Your purchase didn’t go through</p>
          <p className="content mb-4">
            Your CashPally credit balance is <br /> insuffiecient to pay for this item.
          </p>
          <button>Done</button>
        </div>
      </div>
    </div>
  );
}

export default Failure