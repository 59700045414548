import React, { useState, } from "react";
import "./Pin.css";
import OtpInput from "react-otp-input";
import img from "../../assets/img/Vector.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "../login/Login";


const token = localStorage.getItem("token");
const date = localStorage.getItem("date")
const trnx_ref = localStorage.getItem("trnx_ref");

const Pin = () => {
  const [otp, setOtp] = useState("");
  const [disableButton, setDisableButton] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false);
  const handleChange = (otp) => {
    // setOtp(otp);
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (otp === "" || re.test(otp)) {
      setOtp(otp);
    }
  };

  const handleSubmit = (e) => {
    setDisableButton(true);
    setButtonLoad(true)
    e.preventDefault()
    axios({
      method: "post", //you can set what request you want to be
      url: `${baseUrl}/api/request-loan`,
      data: {
        pin: otp,
        user_token: token,
        trnx_ref,
        repayment_date: date,
      },
      headers: {
        Authorization: `Bearer 13LqFGs5TUdnS7PczV8PL5Otx3heloZkm0nU624rAjP0qZh56CCtWh7fZi6Y`,
      },
    })
      .then((response) => {
        setButtonLoad(false);
        setDisableButton(false);
        toast.success(response.data.message);
        setTimeout(() => {
          window.location = response.data.data
        }, 5000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setDisableButton(false);
          setButtonLoad(false);
        } else if (error.response.status === 422) {
          toast.error(error.response.data.message);
          setDisableButton(false);
          setButtonLoad(false);
        } else if (error.response.status === 401) {
          toast.error(error.response.data.message);
          setDisableButton(false);
          setButtonLoad(false);
        } else if (error.response.status === 500) {
          toast.error(error.response.data.message);
          setDisableButton(false);
          setButtonLoad(false);
        }
      });
  }

  return (
    <div className="container mt-5 d-flex justify-content-center align-items-center">
      <div className="row">
        <ToastContainer />
        <div className="col-md-12 card p-3" style={{ maxWidth: "400px" }}>
          <div className="card-body">
            <Link
              to="/"
              className="d-flex justify-content-center align-items-center my-3"
            >
              <img src={img} alt="img" className="header-img" />
            </Link>
            <p className="my-5 mx-1">
              Enter your transaction PIN to confirm this purchase.
            </p>
            <p className="mx-1">PIN</p>
            <form onSubmit={handleSubmit}>
              <OtpInput
                inputStyle={{ border: "none", background: "#FAFAFA" }}
                value={otp}
                onChange={handleChange}
                numInputs={4}
                isInputSecure
                required
                className="otp-input mb-5"
              />
              <div className="mb-3">
                <button
                  type="submit"
                  disabled={disableButton}
                  className="btn btn-success w-100"
                >
                  {buttonLoad ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        fontSize: "11px"
                      }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <span>Continue</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pin;
