import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import img from "../../assets/img/Vector.png";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";

export const baseUrl = process.env.REACT_APP_BASEURL;

let a = window.location.href;
let trnx_ref = a.split("?")[1]

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showEye, setShowEye] = useState(false);
  const [changeType, setChangeType] = useState(true);
  const [redirect, setRedirect] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [buttonLoad, setButtonLoad] = useState(false);

  const search = useLocation().search
  const price = new URLSearchParams(search).get('price')
  localStorage.setItem("amount", price)

  const handleLogin = (e) => {
    setDisableButton(true);
    setButtonLoad(true);
    e.preventDefault();
    axios
      .post(
        `${baseUrl}/api/sign-in`,
        {
          email,
          password,
          trnx_ref,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setButtonLoad(false);
          localStorage.setItem("token", response.data["data"]["token"]);
          localStorage.setItem("loanAmount", response.data.data.amount);
          localStorage.setItem("weeklyFee", response.data.data.weekly_charge);
          localStorage.setItem("trnx_ref", trnx_ref);
          toast.success(response.data.message);
          setRedirect("/loan-request");
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message);
          setButtonLoad(false);
          setDisableButton(false);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setButtonLoad(false);
          setDisableButton(false);
        }
      });
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <>
      <div className="container mt-5">
        <div className="row d-flex justify-content-center align-items-center">
          <ToastContainer autoClose={3000} />
          <div className="col-md-5">
            <div className="card border-0 p-3">
              <div className="card-body">
                <Link to="/" className="mt-2 d-flex justify-content-center align-items-center">
                  <img src={img} alt="img" className="header-img" />
                </Link>
                <h3 className="mt-3">Welcome Back!</h3>
                <p>
                  Log in to your CashPally account to complete your purchase
                </p>
                <form onSubmit={handleLogin}>
                  <div className="my-3">
                    <label className="form-label">Email address</label>
                    <input
                      type="email"
                      className="form-control user-input"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label">Password</label>
                    <input
                      type={changeType ? "password" : "text"}
                      required
                      className="form-control user-input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="float-end mx-3"
                      style={{ marginTop: "-35px", cursor: "pointer" }}
                      onClick={() => {
                        setShowEye(!showEye);
                        setChangeType(!changeType);
                      }}
                    >
                      {showEye ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                  <div className="mt-5">
                    <button
                      type="submit"
                      className="btn btn-success w-100 p-2"
                      disabled={disableButton}
                    >
                      {buttonLoad ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                          style={{
                            width: "20px",
                            height: "20px",
                            fontSize: "11px",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <span>Continue</span>
                      )}
                    </button>
                    <p className="text-center my-3">
                      New User?{" "}
                      <a href="https://cashpally.com/" className="new-user">
                        Create account
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
