import React, { useEffect } from "react";
import './Success.css'

const ecommerceUrl = process.env.REACT_APP_BASEURL;

const Success = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace(`${ecommerceUrl}`);
    }, 3000);
  }, [])

  return (
    <div className="container text-center">
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-md-12" style={{ maxWidth: "400px" }}>
          <p className="head">Your purchase was successful</p>
          <p className="content mb-4">
            You have successfully purchased your item a receipt will be sent to
            your email shortly.
          </p>
          <button>
            <a href="https://cashpally.com/">Done</a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Success