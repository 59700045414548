import React from "react";
import { Link } from "react-router-dom";
import "./OrderDetails.css";
import phone from "../../assets/img/phone.png";
import img from "../../assets/img/Vector.png";

const OrderDetails = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center">
      <div className="row">
        <div className="col-md-12">
          <Link
            to="/"
            className="d-flex justify-content-center align-items-center mt-3"
          >
            <img src={img} alt="img" className="header-img" />
          </Link>
          <p className="my-5 head">
            Here are the details of your order. Click ‘Proceed’ <br /> to
            confirm your purchase.
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <img src={phone} alt="order" className="img-fluid order-img" />
          </div>
          <div className="mt-3">
            <p className="product">Product name: Apple AirPods 2</p>
            <p className="quantity">Quantity: 1 - Colour: White</p>
            <p className="price">Price: N70,000</p>
          </div>
          <div className="row my-5">
            <div className="col my-3">
              <button type="submit" className="proceed">
                Proceed
              </button>
            </div>
            <div className="col my-3">
              <button type="submit" className="cancel">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
